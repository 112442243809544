import PromoImages from "./PromoImages";
import img1 from "../assets/images/promos/pokemon/promo (1).jpg";
import img2 from "../assets/images/promos/pokemon/promo (2).jpg";
import img3 from "../assets/images/promos/pokemon/promo (3).jpg";
import img4 from "../assets/images/promos/pokemon/promo (4).jpg";
import img5 from "../assets/images/promos/pokemon/promo (5).jpg";
import img6 from "../assets/images/promos/pokemon/promo (6).jpg";
import img7 from "../assets/images/promos/pokemon/promo (7).jpg";
import flyer from "../assets/images/promos/pokemon/flyer.jpg";

export default function Pokemon() {
  const images = [img1, img2, img3, img4, img5, img6, img7];
  const name = "pokemon";

  return (
    <>
      <div className="row justify-content-center text-center mb-5">
        <img src={flyer} alt="promo flyer" style={{ maxWidth: "800px" }} />
        {/* <h3 className="mt-5">Details:</h3>
        <ul style={{ listStyleType: "none" }} className="fs-5 mb-5">
          <li>
            Price ranges: $100, $150, $200 (based off size, placement,
            outline/shading/color, etc)
          </li>
        </ul> */}
      </div>
      <PromoImages images={images} name={name} />
    </>
  );
}
