import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  About,
  Artists,
  BigGallery,
  Contact,
  Error,
  Faq,
  Footer,
  Gallery,
  Header,
  Home,
  Locations,
  // Merchandise,
  Piercing,
  ScrollToTop,
  Services,
  artistsList,
} from "./components";
import background from "./assets/images/SA.png";
// import Promo from "./components/Promo";
import PromoPage from "./components/PromoPage";

function App() {
  return (
    <BrowserRouter>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundPositionY: "75%",
          backgroundSize: "60vh",
        }}
      >
        <ScrollToTop />
        <Header />
        {/* <Promo /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/merchandise" element={<Merchandise />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/artists" element={<Artists />} />
          <Route path="/locations" element={<Locations />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<BigGallery />} />
          <Route path="/piercing" element={<Piercing />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="*" element={<Error />} />
          <Route path="/specials" element={<PromoPage />} />

          {artistsList.map((artist) => {
            return (
              <Route
                key={artist.name}
                path={`/gallery/${artist.name.toLowerCase()}`}
                element={
                  <Gallery
                    name={artist.name}
                    files={artist.files}
                    fullName={artist.fullName}
                    location={artist.location}
                    pic={artist.pic}
                    about={artist.about}
                    facebook={artist.facebook}
                    insta={artist.instagram}
                    email={artist.email}
                  />
                }
              />
            );
          })}
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
