import story from "../assets/images/story.jpg";
import turtles from "../assets/images/turtles.png";
import { FaFacebook, FaInstagram, FaYelp } from "react-icons/fa";
import shopInfo from "./shopInfo";
import { Helmet } from "react-helmet";

function About() {
  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo Waikiki | Our Story</title>
      </Helmet>
      <div>
        <section id="about" className="d-flex about_area mt-60 mb-60">
          <div className="container">
            <div className="row justify-content-center mb-50">
              <div className="col-lg-6">
                <div className="section_title text-center pb-10">
                  <h4 className="title">Our Story</h4>
                  <img className="turtles" src={turtles} alt="turtles" />
                </div>
              </div>
            </div>
            <div className="about_content row">
              <div className="picCol">
                <img src={story} alt="about" />
              </div>
              <div>
                <h5 className="about_title">About {shopInfo.title}</h5>
                <p className="about_text">{shopInfo.about}</p>
                {shopInfo.about2 ? (
                  <p className="about_text">{shopInfo.about2}</p>
                ) : (
                  ""
                )}
                {shopInfo.about3 ? (
                  <p className="about_text">{shopInfo.about3}</p>
                ) : (
                  ""
                )}
                <ul className="social">
                  <li>
                    <a href={shopInfo.facebook}>
                      <FaFacebook size={30} />
                    </a>
                  </li>
                  <li>
                    <a href={shopInfo.instagram}>
                      <FaInstagram size={30} />
                    </a>
                  </li>
                  <li>
                    <a href={shopInfo.yelp}>
                      <FaYelp size={30} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default About;
