import turtles from "../assets/images/turtles.png";
import artistsList from "./ArtistsArray";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import getImages from "./GetImages";
import Button from "react-bootstrap/Button";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
import "@fancyapps/ui/dist/fancybox.css";
import LazyLoadImage from "./LazyLoadImage";

function Artists() {
  <Fancybox />;
  const [images, setImages] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const images = await getImages();
        setImages(images);
        setLoad(true);
      } catch (error) {
        console.log("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const newArtists = artistsList.sort(() => {
    return 0.5 - Math.random();
  });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2,
    },
  };

  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    return (
      <div className="carousel-button-group">
        <Button
          style={{ marginRight: "20px" }}
          variant="light"
          className={currentSlide === 0 ? "buttonDisable" : ""}
          onClick={() => previous()}
        >
          <FaArrowLeft />
        </Button>
        <Button
          variant="light"
          className={
            currentSlide === totalItems - slidesToShow ? "buttonDisable" : ""
          }
          onClick={() => next()}
        >
          <FaArrowRight />
        </Button>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo Waikiki | Our Artists</title>
      </Helmet>
      <section id="artists" className="container mt-60 mb-60">
        <div className="row justify-content-center">
          <div className="section_title text-center pb-30">
            <h4 className="title">Our Artists</h4>
            <img className="turtles" src={turtles} alt="turtles" />
          </div>
          <div style={{ textAlign: "center" }} className="pb-30">
            <h3>Click an artist to view their bio & artwork</h3>
          </div>
        </div>

        <div className="carouselArtists">
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            centerMode={false}
            responsive={responsive}
            ssr={true}
            infinite={false}
            arrows={false}
            renderButtonGroupOutside={true}
            customButtonGroup={<ButtonGroup />}
            customTransition="transform 300ms ease-in-out"
          >
            {load &&
              newArtists.map((artist) => {
                const filterImages = images.filter((image) => {
                  return image.includes(artist.files + "-new");
                });
                const firstEight = filterImages.slice(0, 8);
                return (
                  <div key={artist.name}>
                    <Link to={artist.url}>
                      <div className="thumbex">
                        <LazyLoadImage src={artist.pic} alt={artist.name} />
                        <span>
                          <strong>{artist.name}</strong>
                          <p style={{ color: "#aaa" }}>{artist.location}</p>
                        </span>
                      </div>
                    </Link>
                    <div className="container">
                      <div className="row">
                        {firstEight.map((item) => {
                          return (
                            <div
                              key={`${artist.name}.${item}`}
                              className="col-6 col-md-3 mb-15 pl-5 pr-5"
                            >
                              <a data-fancybox={artist.name} href={item}>
                                <LazyLoadImage
                                  className="zoom"
                                  src={item}
                                  alt="gallery pic"
                                />
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
          </Carousel>
        </div>
      </section>
    </>
  );
}

export default Artists;
